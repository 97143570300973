import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { httpInterceptorProviders } from './services/intercept/with-credentials.interceptor';
import { SharedModule } from './theme/shared/shared.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import {
  DxDrawerModule,
  DxListModule,
  DxRadioGroupModule,
  DxToolbarModule,
  DxPivotGridModule
} from 'devextreme-angular';

import { AppComponent } from './app.component';
import { DashboardLayoutComponent } from './theme/layout/dashboard/dashboard.component';
import { NavbarComponent } from './theme/layout/dashboard/nav-bar/nav-bar.component';
import { FooterComponent } from './theme/layout/dashboard/footer/footer.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NotificationService } from '@app-services/notification.service';
/* NgRx Store */
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppReducer } from './store/app.reducer';
import { AppEffects } from './store/app.effects';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    DashboardLayoutComponent,
    NavbarComponent,
    FooterComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot({ app: AppReducer }),
    EffectsModule.forRoot([AppEffects]),
    SharedModule,
    DxDrawerModule,
    DxListModule,
    DxRadioGroupModule,
    DxToolbarModule,
    EditorModule,
    DxPivotGridModule
  ],
  providers: [httpInterceptorProviders, NotificationService],
  bootstrap: [AppComponent],
  schemas: [
  ]
})
export class AppModule {}
