export class User {
  id: number;
  login: string;
  email: string;
  token?: string;
  displayName: string;
  profileImg?: string;
  expiredAt?: number;
  lastLoginTime: string | null;
  isActive: boolean;
}
