<div class="modal-wrapper">
  <h2 mat-dialog-title>
    {{this.title}}
  </h2>
  <mat-dialog-content>
    <p>{{this.message}}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="text-right">
    <button class="mat-raised-button margin-right-2" (click)="onAgree()">{{this.yes}}</button>
    <button mat-raised-button (click)="onClose()">{{this.no}}</button>
  </mat-dialog-actions>
</div>