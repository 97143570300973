import { createSelector } from '@ngrx/store';

export enum AppFields {
  App = 'app',
  Alert = 'alert',
}

export interface AppState {
  [AppFields.Alert]: any;
}

export const InitialState: AppState = {
  [AppFields.Alert]: {
    message: '',
    type: ''
  },
};

export enum AppTypes {
  UpdateState = 'update-app-state',
  SetAlert = 'set-alert',
}

export interface UpdateState { type: AppTypes.UpdateState; payload: any; }
export interface SetAlert { type: AppTypes.SetAlert; }

export const selectAlert = createSelector((state: AppState) => {
  return state[AppFields.App][AppFields.Alert];
}, res => res);

export type AppAction = UpdateState;
