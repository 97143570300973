import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { DashboardLayoutComponent } from './theme/layout/dashboard/dashboard.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./pages/events/events.module').then(
            (module) => module.EventsModule
          ),
      },
      {
        path: 'sessions',
        loadChildren: () =>
          import('./pages/sessions/sessions.module').then(
            (module) => module.SessionsModule
          ),
      },
      {
        path: 'gear-sales',
        loadChildren: () =>
          import('./pages/gear-sales/gear-sales.module').then(
            (module) => module.GearSalesModule
          ),
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./pages/customers/customers.module').then(
            (module) => module.CustomersModule
          ),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./pages/orders/orders.module').then(
            (module) => module.OrdersModule
          ),
      },
      {
        path: 'clinics',
        loadChildren: () =>
          import('./pages/clinics/clinics.module').then(
            (module) => module.ClinicsModule
          ),
      },
      {
        path: 'waitlists',
        loadChildren: () =>
          import('./pages/waitlists/waitlists.module').then(
            (module) => module.WaitlistsModule
          ),
      },
      {
        path: 'transfers',
        loadChildren: () =>
          import('./pages/transfers/transfers.module').then(
            (module) => module.TransfersModule
          ),
      },
      {
        path: 'email-templates',
        loadChildren: () =>
          import('./pages/email-templates/email-templates.module').then(
            (module) => module.EmailTemplatesModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./pages/reports/reports.module').then(
            (module) => module.ReportsModule
          ),
      },
      {
        path: 'scheduled',
        loadChildren: () =>
          import('./pages/scheduled/scheduled.module').then(
            (module) => module.ScheduledModule
          ),
      },
      {
        path: 'swim-team',
        loadChildren: () =>
          import('./pages/swim-team/swim-team.module').then(
            (module) => module.SwimTeamModule
          ),
      },
    ],
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (module) => module.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
