<div class="notifications">
  <div *ngFor="let notification of notifications" class="notification" [ngClass]="className(notification)">
    <ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
  </div>
</div>

<ng-template #notificationTpl let-notification="notification">
  <div class="message">{{notification.message}}
    <button class="btn-close" (click)="close(notification)">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</ng-template>