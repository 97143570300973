<dx-drawer class="main-content" [openedStateMode]="selectedOpenMode" [position]="selectedPosition"
  [revealMode]="selectedRevealMode" template="template" [(opened)]="isDrawerOpen" [height]="'100%'"
  [closeOnOutsideClick]="true">
  <div *dxTemplate="let data of 'template'" style="width: 260px; height: 100vh">
    <div class='dashboard-title'>

      <img width="160px" src="/assets/images/logo.png" />
    </div>
    <div class="user-section">
      <div class="user">
        <img class="user-avatar" [src]="profileImage" />
        <div class="user-info">
          <div class="user-name">{{currentUser.displayName}}</div>
          <div class="user-role">Administrator</div>
          <div class="user-logout" (click)="onLogout()">Logout</div>
        </div>
      </div>
    </div>
    <dx-list class="panel-list dx-theme-custom" [dataSource]="navigation" [hoverStateEnabled]="false"
      [activeStateEnabled]="true" [focusStateEnabled]="false" [selectionMode]="'single'"
      [selectedItemKeys]="selectedMenu" keyExpr="id" (onItemClick)="onMenuItemClick($event)">
    </dx-list>
  </div>
  <div>
    <app-nav-bar [drawerButtonOption]="drawerButtonOption"></app-nav-bar>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</dx-drawer>

<app-notification></app-notification>