import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ComponentProps {
  title: string;
  message: string;
  yes: string;
  no: string;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  message = '';
  title = '';
  yes = 'Agree';
  no = 'Disagree';

  constructor(
    private dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) private props: ComponentProps
  ) {
    if (this.props.title) {
      this.title = this.props.title;
    }
    if (this.props.message) {
      this.message = this.props.message;
    }
    if (this.props.yes) {
      this.yes = this.props.yes;
    }
    if (this.props.no) {
      this.no = this.props.no;
    }
  }

  ngOnInit(): void {}

  onAgree(): void {
    this.dialogRef.close('confirmed');
  }
  onClose(): void {
    this.dialogRef.close('disagree');
  }
}
