import { Component, OnInit, Input } from '@angular/core';
import { UserDropdownMenu } from '@app-models/navigationList';
import { AuthService } from '@app-services/auth.service';
import { getProfileURL } from '@app-utils/helper';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() drawerButtonOption;
  userProfileMenus: UserDropdownMenu[] = [
    { value: 1, name: 'Profile Settings', icon: 'user' },
    { value: 3, name: 'Logout', icon: 'runner' },
  ];

  userMenuOptions = {
    items: this.userProfileMenus,
    splitButton: false,
    useSelectMode: false,
    text: '',
    icon: '',
    displayExpr: 'name',
    width: 185,
    keyExpr: 'id',
    onItemClick: (e) => this.onItemClick(e),
  };

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    const currentUser = this.authService.currentUserValue;
    this.userMenuOptions.text = currentUser.displayName;
    this.userMenuOptions.icon = getProfileURL(currentUser.displayName);
  }

  onItemClick(e): void {
    if (e.itemData.name === 'Logout') {
      // logout
      this.authService.logout();
    }
  }
}
