<dx-toolbar>
  <dxi-item location="before"
    widget="dxButton"
    [options]="drawerButtonOption"
  ></dxi-item>
  <dxi-item location="before"
    widget="dxButton"
    locateInMenu="never">
    <div *dxTemplate>
      
    </div>
  </dxi-item>
  <dxi-item location="after"
    locateInMenu="auto"
    widget="dxDropDownButton"
    [options]="userMenuOptions"
  ></dxi-item>
  
</dx-toolbar>