import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { switchMap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppTypes, AppFields, SetAlert } from './app.models';

@Injectable() export class AppEffects {

  constructor(
    private as: Actions,
  ) {}

  @Effect() setAlert: Observable<Action> = this.as.pipe(
    ofType(AppTypes.SetAlert),
    switchMap((alert: SetAlert) => of(alert)),
    map((res) => ({
      type: AppTypes.UpdateState,
      payload: {
        [AppFields.Alert]: res
      }
    }))
  );
}
