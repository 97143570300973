import * as moment from "moment";

export function getProfileURL(name: string): string {
  const colors = [
    { background: '696969', color: 'fff' },
    { background: 'bada55', color: 'fff' },
    { background: '7fe5f0', color: '000' },
    { background: 'ff0000', color: 'fff' },
    { background: 'ff80ed', color: '000' },
    { background: '407294', color: 'fff' },
    { background: 'cbcba9', color: '000' },
    { background: '420420', color: 'fff' },
    { background: '133337', color: 'fff' },
    { background: '065535', color: 'fff' },
    { background: 'c0c0c0', color: '000' },
    { background: '5ac18e', color: '000' },
    { background: 'dcedc1', color: '000' },
    { background: 'f7347a', color: 'fff' },
    { background: '008080', color: 'fff' },
    { background: 'ffa500', color: '000' },
    { background: '00ffff', color: '000' },
    { background: '800000', color: 'fff' },
    { background: '800080', color: 'fff' },
    { background: '20b2aa', color: 'fff' },
  ];
  const names = name.split(' ');
  let initials = '';
  if (names.length > 1) {
    initials = names[0].substring(0, 1) + names[1].substring(0, 1);
  } else {
    initials = names[0].substring(0, 2);
  }

  let nameSum = initials.charCodeAt(0) + initials.charCodeAt(1);

  const colorIndex = nameSum % colors.length;

  const backgroundColor = colors[colorIndex].background;
  const color = colors[colorIndex].color;
  return `https://ui-avatars.com/api/?background=${backgroundColor}&color=${color}&name=${name}`;
}

export function ageColumnCustomText(value) {
  if (!value) {
    return '';
  }

  let year = moment().diff(value, 'years');
  return `${year}`;
}

export function ageColumnCustomTextEvent(value, eventDate) {
  if (!value || !eventDate) {
    return '';
  }

  let birthday = moment(value);
  let eDate = moment(eventDate);
  let year = eDate.diff(birthday, 'years');
  return `${year}`;
}

export const pluck = (key, array) =>
  array.reduce((values, current) => {
    values.push(current[key]);

    return values;
  }, []);
