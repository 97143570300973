import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@app-models/user';
import { Router } from '@angular/router';
import { yearsPerRow } from '@angular/material/datepicker';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private api: ApiService, private router: Router) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setCurrentUser(user: User): void {
    this.currentUserSubject.next(user);
  }

  isLoggedIn(): boolean {
    const user = this.currentUserSubject.value;
    return user && user.id !== null;
  }

  login(loginData): any {
    return this.api.post('login', loginData).pipe(
      map((resp: any) => {
        const user = new User();
        user.id = resp.user_id;
        user.token = resp.token;
        user.login = loginData.login;
        user.displayName = resp.display_name;
        const decoded = JSON.parse(window.atob(resp.token.split('.')[1]));
        user.expiredAt = decoded.expiredAt;
        user.email = resp.email;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.setCurrentUser(user);
        return resp;
      })
    );
  }

  logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/auth/login']);
  }
}
