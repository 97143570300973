// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiAddress: '/api',
  woo: {
    url: 'https://ffdev.wordjuice.net',
    consumerKey: 'ck_56cef3e442c8463f3075dfbab8a3e7fc0a92358d',
    consumerSecret: 'cs_afcce43265f1c562d48d37e5c58330d1e1b6e25b',
    version: 'wc/v3'
  },
  googlemapKey: 'AIzaSyDSRWvLPheU4PwVSSdvD9JWgccYDbSuUBs'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
