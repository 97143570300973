import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}
  subscription;
  get(uri: string): Observable<any> {
    return this.http.get(`${environment.apiAddress}/${uri}`);
  }

  post(uri: string, data: any): Observable<any> {
    return this.http.post(`${environment.apiAddress}/${uri}`, data);
  }

  put(uri: string, data: any): Observable<any> {
    return this.http.put(`${environment.apiAddress}/${uri}`, data);
  }

  delete(uri: string): Observable<any> {
    return this.http.delete(`${environment.apiAddress}/${uri}`);
  }
}
